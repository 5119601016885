:root {
    --primaryColor: #194D81;
    --primaryRgba: rgba(25, 77, 129, 0.9);
    --secondaryColor: #F5B51B;
    --blackColor: #000;
    --whiteColor: #fff;
    --textGreyColor: #666;
    --lightBgColor: #eef1f4;
    --transition01: .4s all;
    --lightBgColor01: #EDF2FE;
    --redColor: #E44141;
  }