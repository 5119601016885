@import '../../../../style/variable.css';

.menu {
  padding: 0;
  border-bottom: none;
  overflow: auto;
  box-shadow: none;
  background-color: transparent;
  z-index: 100 !important;
  position: fixed;
  width: 100%;
}

.menu-inner {
  position: relative;
  padding: 45px 0px 20px;
  transition: var(--transition01);
}

.innerPageHeader.menu-inner {
  padding: 20px 0;
  background-color: var(--primaryColor);
}

.innerPageHeader.menu-inner .logo {
  width: 170px;
  transition: var(--transition01);
}

.innerPageHeader .header-row {
  align-items: center;
}

.stickyHeader.menu .logo {
  transition: var(--transition01);
}

.stickyHeader.menu .menu-inner {
  background: var(--primaryRgba);
}

.stickyHeader.menu .menu-inner {
  padding: 16px 0;
}

.stickyHeader.menu .logo {
  width: 140px;
}

.menu__logo {
  width: 150px;
  float: left;
}

.menu__logo a {
  display: inline-block;
  font-size: 20px;
  padding: 19px 20px;
}

.menu__container .ant-menu-item {
  padding: 0px 5px;
}

.menu__container .ant-menu-submenu-title {
  padding: 10px 20px;
}

.menu__container .ant-menu-item a,
.menu__container .ant-menu-submenu-title a {
  padding: 10px 15px;
}

.menu__container .ant-menu-horizontal {
  border-bottom: none;
}

.menu__container .menu_left {
  float: left;
}

.menu__container .menu_rigth {
  float: right;
}

.menu__mobile-button {
  float: right;
  height: 32px;
  padding: 6px;
  margin-top: 8px;
  display: none !important; /* use of important to overwrite ant-btn */
  background: #3e91f7;
}

.menu_drawer .ant-drawer-body {
  padding: 0 !important;
}

/* align header of Drawer with header of page */
.menu_drawer .ant-drawer-header {
  padding: 14px 24px !important;
}



/*  */
.c-nav-bar {
  min-width: 500px;
  display: flex;
  justify-content: right;
  align-items: center;
}

.c-nav-bar .c-nav-link {
  margin: 0 16px 0 16px;
  cursor: pointer;
  padding-block: 2px;
  color: var(--whiteColor);
  font-size: 14px;
  border-bottom: 2px solid transparent;
  text-transform: uppercase;
  transition: var(--transition01);
}

.c-nav-bar .c-nav-link:last-child {
  margin-right: 0;
}

.c-nav-bar .c-nav-link:hover, .c-nav-bar .c-nav-link.active {
  border-color: var(--secondaryColor);
  color: var(--secondaryColor);
}

/* Menu Dropdown */
.menuDropdown {
  margin-inline: 16px;
}

.menuDropdown .ant-space-item {
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
}


/* Media Query */
@media (min-width: 901px) {
  .c-m-nav-bar, .c-menu-icon{
    display: none;
    z-index: 100;
  }
}

@media (max-width: 1199.98px) {
  .menu-inner {
      padding: 25px 0px 20px;
  }
}

@media only screen and (max-width: 900px) {
  .menu .logo, .innerPageHeader.menu-inner .logo {
      width: 140px;
  }

  .innerPageHeader.menu-inner {
      padding: 12px 0;
  }

  .stickyHeader.menu .logo {
      width: 120px;
  }

  .stickyHeader.menu .menu-inner {
      padding: 10px 0;
  }  

  .c-nav-bar {
    display: none;
  }

  .c-menu-icon {
    display: block;
    font-size: 24px;
    cursor: pointer;
    color: var(--whiteColor);
  }

  .c-m-nav-bar {
    display: block;
    position: fixed;
    top: 0;
    left: -100%;
    display: block;
    border-radius: 0;
    padding: 0 0 25px 0;
    background-color: rgba(15, 64, 112, 1);
    font-size: 16px;
    text-align: left;
    z-index: 100;
    opacity: 1;
    width: 300px;
    height: 100%;
    transition: var(--transition01);
    box-sizing: border-box;
  }

  .mobileSideLogo {
    padding: 25px 25px 16px;
    display: block;
}

  .c-m-nav-bar.show {
    left: 0;
  }

  .c-m-nav-bar .c-nav-link {
    color: var(--whiteColor);
    font-size: 16px;
    display: block;
    padding: 12px 25px;
    text-transform: uppercase;
  }

  .c-m-nav-bar .c-nav-link:not(:last-child) {
    border-bottom: solid var(--primaryRgba) 1px;
  }

  .c-m-nav-bar .c-nav-link.active {
    color: var(--secondaryColor);
  }

  .serviceMenuMobile .serviceText {
      font-size: 16px;
      color: #fff;
      padding: 13px 25px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: solid var(--primaryRgba) 1px;
  }

  .serviceMenuMobile .servicesMenuWrap {
      background: #064c82;
      height: 0;
      opacity: 0;
      transition: var(--transition01);
      overflow: hidden;
  }

  .serviceMenuMobile .servicesMenuWrap.show {
      height: 121px;
      opacity: 1;
      padding: 6px;
  }

  .c-m-nav-bar .serviceMenuMobile a.c-nav-link {
      border: none !important;
      font-size: 14px;
      padding: 8px 0 8px 25px;
  }
}

@media (max-width: 767px) {
  .menu .logo, .innerPageHeader.menu-inner .logo  {
      width: 120px;
  }

  .menu__mobile-button {
    display: inline-block !important;
  }

  .menu_left,
  .menu_rigth {
    display: none;
  }

  .menu__logo a {
    margin-left: -20px;
  }

  .menu__container .ant-menu-item,
  .menu__container .ant-menu-submenu-title {
    padding: 1px 20px;
  }

  .menu__logo a {
    padding: 10px 20px;
  }
}