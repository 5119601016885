@import '../../../style/variable.css';

.mainfooter {
    background: var(--blackColor);
    color: var(--whiteColor);
    padding: 80px 0 0;
}

.mainfooter .logo {
    margin-bottom: 18px;
}

.mainfooter .link {
    color: var(--whiteColor);
    font-size: 16px;
    display: block;
}

.copyRight-link {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.copyRight-link a.link:not(:first-child) {
    margin-left: 20px;
}

.mainfooter .link:not(:last-child) {
    margin-bottom: 8px;
}

.mainfooter .link:hover {
    color: var(--secondaryColor);
}

.mainfooter .footerTitle {
    color: var(--whiteColor);
    font-size: 18px;
    text-transform: uppercase;
    margin: 0 0 40px;
}

.mainfooter span.ant-typography {
    font-size: 16px;
    color: var(--whiteColor);
    display: block;
}

.mainfooter .recetTweetsTitle {
    color: var(--textGreyColor);
    font-weight: 400;
    font-size: 14px;
    margin: 0 0 4px;
}

.footer-bottom {
    border-top: 1px solid rgba(255,255,255,0.16);
    padding-block: 28px;
    margin-top: 50px;
}

.mainfooter .footer-copyright {
    color: var(--whiteColor);
    font-size: 16px;
}

.mainfooter .footerSocial span {
    font-size: 26px;
    margin-left: 12px;
}

.footerSocial {
    text-align: right;
}



@media only screen and (max-width: 767.98px) {
    .mainfooter .footerTitle {
        margin: 24px 0 16px;
        font-size: 16px;
    }    

    .mainfooter {
        padding: 50px 0 0;
    }

    .footer-bottom {
        padding-block: 20px;
        margin-top: 30px;
    }

    .mainfooter .link, .mainfooter span.ant-typography {
        font-size: 15px;
    }
}

@media only screen and (max-width: 575.98px) {
    .footer-copyright, .footerSocial {
        text-align: center;
    }
    .copyRight-link {
        justify-content: center;
        margin-top: 10px;
    }
}