@import './variable.css';

/* Common Css */
/* html {
  scroll-behavior: smooth !important;
} */

body {
  font-family: 'DM Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  line-height: 1.5;
}

.textCenter {
  text-align: center;
}

.sectionPadding {
  padding: 120px 0;
}

.sectionTilteWrap {
  margin-bottom: 40px;
}

.sectionTilteWrap .sectionTilte {
  font-size: 32px;
  color: var(--blackColor);
  margin-bottom: 20px;
}

.sectionTilteWrap span {
  font-size: 16px;
  color: var(--blackColor);
  line-height: 1.6;
}

.sectionTilteWrap span a {
  color: var(--primaryColor);
  font-weight: 500;
}

.container {
  width: 100%;
  max-width: 1450px;
  margin: 0 auto;
  padding: 0px 25px;
}

.positionDefault {
  position: static;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.mainfooter {
  margin-top: auto;
}

/* Button */
.primaryBtn {
  line-height: 60px;
  height: 60px;
  padding: 0 30px;
  text-transform: uppercase;
  font-size: 18px;
  background: var(--secondaryColor);
  border: 1px solid var(--secondaryColor);
  color: var(--primaryColor);
  font-weight: 700;
  transition: var(--transition01);
  cursor: pointer;
  border-radius: 8px;
  display: inline-block;
}

.primaryBtn.smallBtn {
  line-height: 48px;
  height: 48px;
  font-size: 16px;
  padding: 0 24px;
}

.primaryBtn.outlineSmallBtn {
  line-height: 48px;
  height: 48px;
  font-size: 16px;
  padding: 0 24px;
  background-color: transparent;
  border-color: var(--primaryColor);
  columns: var(--primaryColor);
}

.primaryBtn:disabled {
  cursor: not-allowed;
  opacity: 0.5;
  transform: translateY(0) !important;
  color: var(--primaryColor) !important;
}

.widthBtn {
  width: 260px;
}

.primaryBtn:hover {
  background-color: var(--secondaryColor);
  border-color: var(--secondaryColor);
  transform: translateY(-6px);
  color: var(--blackColor);
}

.primaryBtn:focus {
  border-color: var(--secondaryColor);
  background: var(--secondaryColor);
  color: var(--blackColor);
}

.spinBtn .ant-spin-nested-loading {
  display: inline-block;
}

.spinBtn .ant-spin-nested-loading>div>.ant-spin {
  position: absolute;
  top: 50%;
  inset-inline-start: 50%;
  margin: 0;
}


/* Table */
table {
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #ededed;
}

.StripeElement {
  margin: 15px auto;
  padding: 10px 12px;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}


/* Banner */
.homeBanner {
  background-image: url(../assets/images/VideoSign/bannerVideo.mp4);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  /* padding: 290px 0 290px; */
  background-position: center center;
  max-height: 780px;
  overflow: hidden;
}

.homeBanner::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(25, 77, 129, 0.3);
  inset: 0;
  z-index: 0;
}

.bannerContent {
  position: relative;
  z-index: 1;
}

.bannerVideo {
  height: 100%;
}

.bannerVideo .bannerGif {
  width: 100%;
  height: 100%;
}

.bannerVideo+.container {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bannerContent h1 {
  margin: 0 0 20px;
  font-size: 65px;
  color: var(--whiteColor);
  text-transform: uppercase;
}

.bannerContent h2 {
  margin: 0 0 30px !important;
  font-size: 20px;
  color: var(--whiteColor);
  font-weight: normal;
  text-transform: uppercase;
}

.bannerContent h2.JapaneseLang {
  margin: 0 0 10px !important;
}

/* :lang(en) .bannerContent h2.JapaneseLang {
  display: none;
} */

.scrollDwon {
  position: absolute;
  width: 30px;
  height: 50px;
  border: 2px solid var(--whiteColor);
  bottom: 30px;
  border-radius: 15px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.scrollDwon::after {
  content: "";
  height: 13px;
  width: 2px;
  background: var(--whiteColor);
  position: absolute;
  left: 12px;
  top: 8px;
  border-radius: 2px;
}


/* Service Section */
.serviceBlock {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.serviceBlock img {
  width: 52px;
  height: 52px;
  margin-bottom: 14px;
  object-fit: contain;
}

.serviceBlock span {
  font-size: 18px;
  max-width: 300px;
  margin: 2px auto 0;
  color: var(--textGreyColor);
}


/* Full Width sigle Img Section */
.fullWidthBlock {
  position: relative;
  background-color: #f5f5f5;
}

.fullWidthBlock.sectionPadding {
  padding: 140px 0;
}

.leftRightImg .ant-image {
  position: static;
}

.leftRightImg img {
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  object-fit: cover;
}

.rightImg .leftRightImg img {
  left: auto;
  right: 0;
}

.leftRightContent {
  padding-left: 55px;
}

.rightImg .leftRightContent {
  padding-left: 0;
  padding-right: 55px;
}

.rightImg .leftRightImg img {
  left: auto;
  right: 0;
}

.leftRightContent {
  padding-left: 55px;
}

.rightImg .leftRightContent {
  padding-left: 0;
  padding-right: 55px;
}

.fullWidthBlock .serviceBlock {
  margin-top: 30px;
}

.fullWidthBlock .serviceBlock span {
  font-size: 14px;
  max-width: 190px;
  font-weight: 600;
}


/* Featured Card */
.featuredCard {
  border: none;
  margin-bottom: 24px;
  height: calc(100% - 24px);
}

.featuredCard .ant-card-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 30px;
  background-color: var(--lightBgColor);
  transition: var(--transition01);
  height: 100%;
}

.featuredCard .ant-card-body .cardText {
  flex-basis: calc(100% - 20px);
}

.featuredCard .ant-card-body .cardArrow {
  flex-basis: 20px;
  text-align: center;
}

.featuredCard .cardText h5 {
  margin: 0 0 2px;
  font-size: 18px;
}

.featuredCard .cardText span {
  color: var(--blackColor);
  font-size: 16px;
}

.featuredCard .cardArrow .anticon {
  font-size: 30px;
}

.featuredCard .ant-card-body:hover {
  background-color: var(--primaryColor);
}

.featuredCard .ant-card-body:hover .cardText span,
.featuredCard .ant-card-body:hover .cardText h5 {
  color: var(--whiteColor);
}

.featuredCard .ant-card-body:hover .cardArrow .anticon {
  color: var(--secondaryColor);
}


/* Bg Section */
.bgSection {
  background-image: url(../assets/images/VideoSign/bg01.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding: 150px 20px;
  background-position: center center;
}

.bgSection::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--primaryRgba);
  inset: 0;
  z-index: 0;
}

.bgSection .spanText {
  display: block;
  margin: 30px 0 0;
  color: var(--whiteColor);
  font-size: 18px;
}

.bgSection .bannerContent h1 {
  margin: 0 0 20px;
  font-size: 45px;
}

.bgSection .bannerContent h2 {
  font-size: 25px;
  margin-bottom: 40px;
}


/* Contact Us Section */
.contactSectionWrap {
  padding: 80px 0;
}

.contactSection {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.contactEmail span {
  color: var(--textGreyColor);
  font-size: 21px;
  margin-right: 30px;
}


/* Login Popup */
.ant-modal.sign-in-up {
  width: 100% !important;
  max-width: 580px;
}

.sign-in-up .ant-modal-header {
  border: 0;
  padding: 45px 75px 24px;
  display: block;
  text-align: left;
}

.sign-in-up .ant-modal-body {
  padding: 0px 75px 50px;
}

.sign-in-up .ant-modal-title {
  font-size: 40px;
  line-height: 1.4;
  display: inline-block;
  color: var(--primaryColor);
  font-weight: 500;
  text-transform: capitalize;
}

.sign-in-up .ant-modal-footer {
  display: none;
}

.popupText {
  color: var(--primaryColor);
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  display: block;
}

.popupText.noNeed {
  font-weight: 700;
  margin-bottom: -10px;
}

.popupText.popupLink {
  margin-top: 40px;
}

.agreeLinksWrap .popupText {
  margin-top: 24px;
  font-size: 13px;
}

.popupText.popupLink a {
  color: var(--blackColor);
  font-weight: 500;
}

.google-sign {
  width: 100% !important;
  padding: 5px 0 !important;
  border: none !important;
  box-shadow: none !important;
  border-radius: 30px !important;
  margin: 30px 0px 24px !important;
  opacity: 1 !important;
  justify-content: center;
  height: 52px;
}

.google-sign iframe {
  margin-inline: auto !important;
}

.google-signUp iframe {
  margin-top: -3px !important;
}

.google-sign span {
  font-size: 16px;
}

.google-sign svg {
  margin-top: 4px;
}

.sign-or {
  position: relative;
}

.sign-or::before {
  content: "";
  position: absolute;
  width: 47%;
  height: 1px;
  background-color: #eaeaea;
  left: 0;
  top: 50%;
}

.sign-or::after {
  content: "";
  position: absolute;
  width: 47%;
  height: 1px;
  background-color: #eaeaea;
  right: 0;
  top: 50%;
}

.formLineBtn {
  background: #06c755;
  display: flex;
  align-items: center;
  max-width: 254px;
  margin: 0 auto;
  border-radius: 12px;
  transition: var(--transition01);
  cursor: pointer;
}

.formLineBtn:hover {
  background-color: #05b34c;
}

.formLineBtn:focus,
.formLineBtn:active {
  background-color: #048b3b;
}

.formLineBtn .btnImg {
  flex-basis: 70px;
  border-right: 3px solid rgba(0, 0, 0, 0.08);
  padding: 10px;
}

.formLineBtn .btnImg img {
  width: 38px;
}

.lineRegistration .formLineBtn .btnImg {
  padding: 8px;
}

.lineRegistration .formLineBtn .btnImg img {
  width: 34px;
}

#signInDiv iframe {
  transform: scale(1.4);
}

#signInDiv.google-signUp iframe {
  transform: scale(1.4);
}


.formLineBtn .btnText {
  flex-basis: calc(100% - 70px);
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: var(--whiteColor);
}


/* Registration Agree Popup */
.registrationAgreePopup .registrationTermsPage {
  height: 260px;
  overflow-y: auto;
  border: 1px solid #ddd;
  margin-bottom: 20px;
  padding: 20px;
}

.registrationTermsPage .container {
  padding: 0;
}

.registrationAgreePopup .contentPages.content {
  margin: 0;
  padding: 0;
  text-align: left;
}

.registrationAgreePopup .contentPages.content p,
.registrationAgreePopup .contentPages.content li {
  font-size: 14px;
}

.registrationAgreePopup .contentPages h3 {
  font-size: 20px;
}

.registrationAgreePopup .contentPages .main__title {
  font-size: 24px;
}

.agreeCheckBox {
  margin-bottom: 24px;
}

.agreeCheckBox label.ant-checkbox-wrapper {
  width: 100%;
  text-align: left;
}


/* Services Pages */
.content {
  margin: 100px 0;
  padding-top: 96px;
}

.content .mainTitle {
  font-size: 40px;
  color: var(--blackColor);
  margin-bottom: 40px;
}


/* Accordion */
.faq__wrapper .ant-collapse {
  background-color: transparent;
}

.faq__wrapper .ant-collapse-item {
  border: 1px solid var(--lightBgColor) !important;
}

.faq__wrapper .ant-collapse-item:not(:last-child) {
  margin: 0 0 20px;
}

.faq__wrapper .ant-collapse .ant-collapse-header {
  color: var(--primaryColor);
  font-size: 20px;
  font-weight: 500;
  background-color: var(--lightBgColor);
}

.faq__wrapper .ant-collapse-item.ant-collapse-item-active .ant-collapse-header {
  color: var(--secondaryColor);
}

.faq__wrapper .ant-collapse-content>.ant-collapse-content-box {
  padding: 20px !important;
  font-size: 16px;
}


/* Deshboard Content Common */
.dashboardWrap {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-flow: wrap;
}

.adminRightContent {
  width: calc(100% - 292px);
  margin-left: auto;
  position: relative;
  transition: var(--transition01);
}

.adminSideNav.hide+.adminRightContent {
  width: 100%;
}

.dashboardContentWrap {
  margin-top: 0;
  padding: 50px;
}

.darboartTitleWrap {
  margin-bottom: 60px;
}

.darboartTitleWrap .dashBoardPageTitle {
  font-size: 44px;
  color: var(--primaryColor);
  font-weight: 700;
  margin-bottom: 12px;
}


/* Admin Top Nav */
.adminTopNav {
  background-color: #fff;
  padding: 25px 50px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 9;
}

.adminSideToggle svg {
  width: 25px;
  height: 25px;
  margin-top: 5px;
  color: var(--primaryColor);
  cursor: pointer;
}

.adminRightMenu {
  display: flex;
  align-items: center;
  gap: 24px;
}

.adminUser {
  display: flex;
  align-items: center;
  gap: 10px;
}

.adminUser img.user-logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: contain;
}

.adminUser .userName {
  font-size: 14px;
  font-weight: 700;
  color: #2C70B8;
}

.adminUser .userName .myProfileLink {
  font-weight: 500;
  color: #7A7A7A;
  display: block;
}

.notiIcon {
  background: var(--lightBgColor);
  width: 40px;
  height: 40px;
  flex-basis: 40px;
  text-align: center;
  line-height: 36px;
  border-radius: 50%;
  margin-left: 16px;
}


/* Admin Side Nav */
.adminSideNav {
  box-shadow: 6px 4px 13px rgba(0, 0, 0, 0.25);
  height: 100%;
  flex-basis: 292px;
  background-color: var(--secondaryColor);
  transition: var(--transition01);
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 292px;
  z-index: 12;
  transform: translateX(0);
}

.adminSideNav.hide {
  transform: translateX(-105%);
}

.adminLogo {
  padding: 16px 10px;
  background-color: #F5F7FE;
}

.adminMenu .adminNavLink {
  padding: 10px 30px;
  font-size: 14px;
  color: #fff;
  font-weight: 700;
  border-top: 1px solid rgba(255, 255, 255, 0.22);
  text-transform: uppercase;
  height: 75px;
  display: flex;
  align-items: center;
  transition: var(--transition01);
  white-space: nowrap;
}

.adminMenu .adminNavLink.navActive,
.adminMenu .adminNavLink:hover {
  background-color: var(--primaryColor);
}

.adminMenu .adminNavLink img {
  width: 30px;
  height: auto;
  object-fit: contain;
  margin-right: 12px;
}


.adminMenu ul {
  background: transparent;
  border: none;
}

.adminMenu ul .ant-menu-item {
  background: transparent !important;
  padding: 10px 30px !important;
  height: 75px !important;
  margin: 0 !important;
  display: flex;
  align-items: center;
}

.adminMenu ul .ant-menu-item:not(:last-child) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.22);
}

.adminMenu ul .ant-menu-item:hover,
.adminMenu ul .ant-menu-item-selected {
  background-color: var(--primaryColor) !important;
}

.adminMenu ul .ant-menu-item .ant-menu-title-content {
  font-weight: 700 !important;
  color: #fff !important;
  text-transform: uppercase;
}

.adminMenu ul .ant-menu-item .ant-menu-title-content img {
  width: 30px;
  height: auto;
  object-fit: contain;
  margin-right: 12px;
}

/* Dashboard Page */
.dashboardContent {
  max-width: 1360px;
  width: 100%;
  margin: 0 auto;
}

.dashboardCard {
  box-shadow: 0px 0px 38.6613px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  padding: 70px 32px;
  background: #fff;
  display: flex;
  flex-direction: column;
  height: calc(100% - 24px);
  margin-bottom: 24px;
  transition: var(--transition01);
  cursor: pointer;
  min-height: inherit;
  position: relative;
}

.dashboardCard:hover {
  transform: translateY(-6px);
}

.dashboardCard .cardTitle {
  font-size: 32px;
  color: var(--whiteColor);
  font-weight: 600;
  margin: 0;
  line-height: normal;
  background-color: var(--secondaryColor);
  padding: 16px 5px;
  transition: var(--transition01);
}

.dashboardCard .fullLink {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  inset: 0;
}

.dashboardCard .cardDesWrap {
  margin: 50px 0 0;
}

.dashboardCard .cardDes {
  color: #162839;
  font-size: 14px;
  margin: 0;
}

.dashboardCard .readMore,
.dashboardCard .ant-typography-expand {
  color: var(--primaryColor);
  font-size: 14px;
  font-weight: 600;
  margin-left: 3px;
  position: relative;
  z-index: 2;
}

.dashboardCard .readMore:hover {
  color: var(--secondaryColor);
}

.dashboardCard .primaryBtn {
  margin-top: auto;
}


/* Step Form */
.stepProcessBar .ant-steps-item-container {
  text-align: center;
}

.stepProcessBar .ant-steps-item {
  overflow: visible;
}

.stepProcessBar .ant-steps-item:last-child {
  flex: 1 1;
}

.stepProcessBar .ant-steps-item-content {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -36px;
}

.stepProcessBar .ant-steps-small .ant-steps-item-icon {
  margin: 0 auto;
  background: #DFE3F1;
  font-size: 0;
  border: none;
}

.stepProcessBar .ant-steps-small .ant-steps-item-active .ant-steps-item-icon {
  background: var(--secondaryColor);
  border: 6px solid #DFE3F1;
  width: 32px;
  height: 32px;
  margin-top: -4px;
}

.stepProcessBar .ant-steps-small .ant-steps-item-title::after {
  display: none;
}

.stepProcessBar .ant-steps {
  position: relative;
  margin-bottom: 70px;
}

.stepProcessBar .ant-steps-small .ant-steps-item-title {
  padding-right: 0;
  color: #DFE3F1 !important;
}

.stepProcessBar .ant-steps-small .ant-steps-item-active .ant-steps-item-title {
  color: var(--primaryColor) !important;
}

.stepProcessBar .ant-steps::after {
  content: '';
  position: absolute;
  width: 75%;
  border-top: 1px dashed #DFE3F1;
  top: 12px;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);
}

.stepFormCard .uploadPDF {
  width: 100%;
  background: #F9FAFF;
  height: 160px;
  border: 1px dashed #DFE3F1;
  color: var(--primaryColor);
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 12px;
  margin-bottom: 24px;
  cursor: pointer;
  padding: 12px;
  text-align: center;
}

.stepFormCard .ant-spin-spinning {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.uploadPDF span {
  font-size: 16px;
  color: var(--primaryColor);
  margin-top: 12px;
}

.uploadMsg {
  display: block;
  font-size: 12px;
  margin: 10px 0 0;
  color: var(--primaryColor);
  font-weight: 500;
}


/* Funnel */
.funnelContent {
  max-width: 100%;
  margin: 0 auto;
  position: relative;
}

.funnelContent .stepProcessBar,
.funnelContent .stepFormCard,
.funnelContent .posTopText {
  max-width: 680px;
  margin: 0 auto;
}

.funnelContent .circleImg {
  position: absolute;
}

.funnelContent .circle01 {
  right: 20%;
  top: 80px;
  z-index: -1;
}

.funnelContent .circle02 {
  left: 0%;
  top: 50%;
  max-width: 500px;
  width: 100%;
  z-index: -1;
}

.stepForm .stepFormTitle {
  font-size: 44px;
  color: var(--primaryColor);
  font-weight: 700;
  margin: 40px 0;
}


/* Step Form Card */
.stepFormCard {
  box-shadow: 0px 0px 66px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
  padding: 40px;
  margin: 100px auto 24px !important;
  background-color: #fff;
  position: relative;
}

.stepFormCard .formCardTitle {
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  margin: 0 0 24px;
  color: var(--primaryColor);
}

.stepFormCard .formCardSubTitle {
  font-size: 16px;
  color: var(--primaryColor);
  margin: 0 0 24px;
  text-align: center;
  display: block;
}

.stepFormCard .primaryBtn {
  min-width: 200px;
  text-transform: capitalize;
}


/* Next Previous Button */
.stepFormBtn .btn {
  background: #194D81;
  border: none;
  margin: 5px 10px;
  line-height: 44px;
  padding: 0 10px;
  max-width: 150px;
  width: 100%;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  position: relative;
  cursor: pointer;
  border-radius: 8px;
  height: 44px;
}

.stepFormBtn.videoStepBtn .btn {
  max-width: 190px;
}

.stepFormBtn .btn.preBtn {
  padding-left: 24px;
}

.stepFormBtn {
  text-align: center;
}

.stepFormBtn .btn svg {
  width: 22px;
  height: 22px;
  color: var(--secondaryColor);
}

.stepFormBtn .btn .arrowIcon {
  position: absolute;
  top: 11px;
}

.stepFormBtn .btn.nextBtn .arrowIcon {
  right: 8px;
}

.stepFormBtn .btn.preBtn .arrowIcon {
  left: 8px;
}

.stepFormBtn .btn:disabled {
  background-color: #DFE3F1;
  cursor: not-allowed;
}

.stepFormBtn .btn:disabled svg {
  color: var(--whiteColor);
}

.addRecepientsForm {
  background: #EDF1FF;
  padding: 20px 24px 24px;
  border-radius: 12px;
  max-width: 544px;
  margin: 0 auto;
  position: relative;
}

.addRecepientsForm:not(:last-child) {
  margin-bottom: 24px;
}

.recepientsClose {
  background: #F5F7FE;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  width: 44px;
  height: 44px;
  text-align: center;
  line-height: 44px;
  border-radius: 50%;
  position: absolute;
  right: 24px;
  top: 16px;
  cursor: pointer;
}

.addRecipientWrap .addRecepientsForm:first-child .recepientsClose {
  display: none;
}

.recepientsAdd {
  background: var(--secondaryColor);
  color: #fff;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: absolute;
  right: 30px;
  bottom: -30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.recepientsAdd svg {
  width: 32px;
  height: 32px;
  color: var(--primaryColor);
}

.addRecepientsForm .title,
.recipientDetail .title {
  color: var(--primaryColor);
  font-size: 24px;
  font-weight: 500;
  margin: 0 0 16px;
}

.formGroup {
  margin-bottom: 16px;
  position: relative;
}

.formGroup .formIcon {
  position: absolute;
  right: 20px;
  bottom: 24px;
}

.formGroup label {
  font-size: 13px;
  font-weight: 400;
  color: #7A7A7A;
  margin: 0 0 4px 0;
  display: block;
}

.formControl {
  height: 48px;
  border: 1px solid #DBDAD9;
  border-radius: 30px;
  color: #3E3157;
  font-size: 16px;
  font-weight: 400;
  padding: 0 22px;
  margin-bottom: 12px;
  font-family: 'DM Sans', sans-serif;
}

.ant-dropdown-menu {
  transform: translateY(12px);
}

.formControl::placeholder {
  color: #7A7A7A !important;
}

.docFile {
  margin: 0 auto;
  width: 100%;
}

.docFileImg {
  background: #DFE3F1;
  padding: 12px;
  border-radius: 0;
  margin: 0;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
}

.docFileImg img {
  height: auto;
  width: 100%;
  border: none !important;
  margin: 0 0 16px !important;
}

.docFileImgMobile {
  background: #dfe3f1;
  padding: 12px;
  border-radius: 0;
  margin: 0;
  height: 420px;
  overflow-y: auto;
  overflow-x: hidden;
}

.docFileImgMobile .mobileScrollWindow>div .pdfMobile {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.docFileImgMobile .pdfMobile {
  margin: 0 0 10px !important;
}

.popup {
  z-index: 9;
  height: 35px;
  position: absolute;
  resize: both;
  overflow: hidden;
}

.popup-header {
  object-fit: contain;
  object-position: 0% 0%;
  cursor: move;
  z-index: 10;
  background-color: #2196f388;
  color: #fff;
  overflow: hidden;
}

.stepFormCard .docName {
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 10px;
  color: var(--primaryColor);
  word-break: break-all;
}

.stepFormCard span.docName {
  font-weight: 400;
  margin-bottom: 10px;
  display: block;
}

.recipientDetail {
  background: #EDF1FF;
  border-radius: 12px;
  padding: 12px 20px;
}

.recipientDetailWrap:not(:last-child) .recipientDetail {
  margin-bottom: 12px;
}

.recipientDetail .title {
  margin: 0 0 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.recipientDetail .recipientPerson {
  font-size: 16px;
  color: var(--primaryColor);
}

.fileDetial {
  background: var(--secondaryColor);
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 12px;
}

.fileThumbnail img {
  width: 100%;
}

.stepFormCard .fileDetial .docName {
  margin: 0;
}

.stepFormCard .fileDetial .fileThumbnail+.docName {
  margin-top: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.agreeTxt {
  margin: 20px 0 30px;
}

.agreeTxt * {
  color: var(--primaryColor);
  font-size: 14px;
}

.agreeTxt a {
  color: var(--secondaryColor);
}

.stepForm.setPOSPage {
  display: flex;
  gap: 20px;
  align-items: center;
  max-width: 1170px;
  margin: 0 auto;
}

.signConfirmPopup {
  justify-content: center;
}

.setPOSPage .stepFormBtn {
  flex-basis: 150px;
}

.setPOSPage .stepFormBtn .btn {
  margin: 0;
}

.setPOSPage .stepFormCard {
  overflow: hidden;
}

.funnelContent .setPOSPage .stepFormCard {
  flex-basis: 870px;
  max-width: 870px;
  margin-top: 40px !important;
}

.posTopText .topText {
  font-size: 16px;
  display: block;
  color: var(--primaryColor);
  font-weight: 600;
}

.signCustom div>div {
  display: flex;
  align-items: center;
}

.signCustom div>div button {
  border: 1px solid #ddd;
  background: #fff;
  width: 28px;
  height: 28px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  margin: 0 1px;
}

.signCustom div>div button:nth-child(1) {
  color: var(--redColor);
  font-size: 24px;
}

.signCustom .popup {
  margin-right: 2px;
  background: #fdff86 !important;
  border: 2px dashed #194d81 !important;
  /* padding: 6px; */
  object-fit: contain;
}

.signCustom .popup[id^=rid0] {
  background-color: transparent !important;
}

.signCustom .popup-header {
  background: transparent !important;
}

.signCustom .popup+div {
  top: 0;
}

.signCustom div>div img {
  margin: 0 !important;
}

.signCardWrap {
  max-height: 500px;
  overflow-y: auto;
}

.signCard {
  cursor: pointer;
}

.signCard .ant-card-body {
  padding: 10px;
  display: flex;
  align-items: center;
  flex-flow: wrap;
}

.signCard:not(:last-child) {
  margin-bottom: 10px;
}

.signChangePopup .ant-modal-footer {
  text-align: center;
}

.changeSignInnerPopup .sign-area {
  cursor: pointer;
}

.changeSignInnerPopup .chosen-sign {
  border: 1px solid var(--redColor);
}

.smallOutlineBtn {
  margin: 2px;
  color: var(--whiteColor);
  background-color: var(--secondaryColor);
  opacity: 0.8;
  padding: 5px 6px;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 500;
  line-height: 12px;
  cursor: pointer;
  border: 1px solid #ddd;
}

.smallOutlineBtn.deleteSmallBtn {
  background-color: var(--redColor);
}

.signCardImg {
  flex-basis: calc(100% - 68px);
  padding-right: 10px;
}

.signCardBtnWrap {
  flex-basis: 68px;
  display: flex;
}

.changeSignInnerPopup .formGroup label {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.changeSignInnerPopup .formGroup .formControl {
  width: 100%;
  padding-block: 7px;
}

.changeSignInnerPopup .ant-tabs-tabpane {
  font-size: 16px;
  color: var(--primaryColor);
}

.changeSignInnerPopup .langSelect {
  line-height: 46px;
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 0px 22px;
  cursor: pointer;
  margin-bottom: 12px;
  border: 1px solid #DBDAD9;
}

.changeSignInnerPopup .langdropdown {
  position: absolute;
  top: 52px;
  background-color: var(--lightBgColor);
  text-align: left;
  width: 94%;
  padding: 5px 22px;
  z-index: 9;
  border-radius: 8px;
}

.changeSignInnerPopup .langdropdown .lang-area {
  margin: 10px 0;
  cursor: pointer;
  color: #333;
}

.changeSignInnerPopup .canvas-container,
.changeSignInnerPopup .canvas-container canvas {
  width: 100% !important;
}


/* Sign & Stamp Page */
.signPage {
  max-width: 1000px;
  margin: 0 auto;
}


/* Request Popup */
.requestPopup {
  max-width: 550px;
  width: 100% !important;
}

.requestPopupBody {
  text-align: center;
}

.requestPopupBody img {
  margin: 0 0 20px 50px;
}

.requestPopupBody h3 {
  font-size: 32px;
  font-weight: 500;
  color: var(--primaryColor);
  line-height: 1.8;
  margin: 0 0 10px;
}

.requestPopupBody h5 {
  font-size: 18px;
  font-weight: 500;
  color: var(--primaryColor);
  line-height: 1.4;
  margin: 0;
}

.requestPopup .ant-modal-footer {
  border-top: none;
  padding: 10px 20px 50px;
  text-align: center;
}

.requestPopup .ant-modal-footer button {
  line-height: 60px;
  height: 60px;
  padding: 0 30px;
  text-transform: uppercase;
  font-size: 18px;
  background: var(--secondaryColor);
  border: 1px solid var(--secondaryColor);
  color: var(--primaryColor);
  font-weight: 700;
  transition: var(--transition01);
  cursor: pointer;
  border-radius: 8px;
  display: inline-block;
  max-width: 300px;
  width: 100%;
}

.requestPopup .ant-modal-close {
  display: none;
}


/* Swal Css   */
.swal2-container.swal2-center>.swal2-popup {
  padding: 40px 20px;
  width: 100%;
  max-width: 440px;
}

.swal2-actions button {
  display: inline-block;
  line-height: 48px;
  height: 48px;
  padding: 0 24px;
  text-transform: uppercase;
  font-size: 16px !important;
  font-weight: 700;
  transition: var(--transition01);
  cursor: pointer;
  border-radius: 8px !important;
  min-width: 140px;
}

.swal2-html-container {
  font-family: 'DM Sans', sans-serif !important;
  color: var(--primaryColor) !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
  margin: 16px 10px !important;
}

.swal2-actions .swal2-styled.swal2-confirm {
  background: var(--secondaryColor) !important;
  border: 1px solid var(--secondaryColor) !important;
  color: var(--primaryColor);
}

.swal2-actions .swal2-styled.swal2-deny,
.swal2-actions .swal2-styled.swal2-cancel {
  background: var(--redColor) !important;
  border: 1px solid var(--redColor) !important;
  color: var(--whiteColor);
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: none !important;
}

.swal2-container .swal2-title {
  display: block;
  font-size: 26px;
  font-weight: 500;
  color: var(--primaryColor);
  line-height: 1.4;
  margin: 0 0 10px;
  padding: 0;
}

/* Video Sign Page */
.videoSignWrap {
  display: flex;
  width: 100%;
  gap: 20px;
  margin: 100px auto 40px;
  align-items: flex-start;
  max-width: 1520px;
  justify-content: center;
}

.videoImportant {
  flex-basis: 25%;
  position: relative;
}

.messageIcon {
  width: 66px;
  height: 66px;
  background: var(--lightBgColor01);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.importantText {
  position: absolute;
  width: 100%;
  background: #f7f7f7;
  z-index: 2;
  opacity: 1;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  padding: 10px 24px 24px;
  top: calc(100% + 32px);
  transform: var(--transition01);
}

.messageIcon+.importantText.hide {
  z-index: -1;
  opacity: 0;
}

.importantText::before {
  content: '';
  width: 0px;
  height: 0;
  border-bottom: 36px solid #f7f7f7;
  border-right: 36px solid transparent;
  position: absolute;
  top: -30px;
  left: 31px;
}

.importantText .importantBtn {
  background: var(--redColor);
  border-radius: 4px;
  line-height: 18px;
  font-size: 14px;
  padding: 4px 5px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  max-width: 90px;
  margin: 0 auto;
  text-align: center;
}

.importantText .title {
  color: #162839;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  margin: 12px 0;
}

.importantListWrap .importantList:not(:last-child) {
  margin-bottom: 20px;
}

.importantList {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

}

.importantList span {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondaryColor);
  font-size: 14px;
  font-weight: 700;
  color: var(--whiteColor);
  border-radius: 50%;
}

.importantList p {
  flex-basis: calc(100% - 36px);
  padding-left: 16px;
  color: #162839;
  font-size: 16px;
  line-height: 21px;
  margin: 0;
}


.videoDiv {
  background-color: #fff;
  box-shadow: 0px 0px 89.6342px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
  padding: 32px;
  flex-basis: 55%;
  text-align: center;
  min-height: 548px;
}

.videoDivInner {
  background-color: var(--primaryColor);
  padding: 20px;
  border-radius: 8px;
}

.videoDiv video {
  margin: 0 auto;
  width: 100%;
}

.videoBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.recordingBtn .btn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 5px;
  outline: none;
  border: none;
  background: rgba(255, 255, 255, 0.15);
  border: 2px solid #FFFFFF;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.recordingBtn .btn.rec {
  background-color: #fff;
}

.recordingBtn .btn span {
  width: 32px;
  height: 32px;
  background-color: darkred;
  border-radius: 50%;
  transition: var(--transition01);
}

.recordingBtn.playPauseBtn .btn span {
  background: transparent;
  width: auto;
  height: auto;
  text-align: center; 
}

.recordingBtn.playPauseBtn .btn.playBtn span {
  padding-left: 4px;
}

.recordingBtn.playPauseBtn .btn.ant-btn[disabled], .recordingBtn.playPauseBtn .btn.ant-btn[disabled]:hover {
  background-color: rgba(255, 255, 255, 0.15);
  border-color: #808080;
}

.recordingBtn.playPauseBtn .btn.ant-btn[disabled] svg {
  fill: #808080;
}

.recordingBtn.playPauseBtn .btn span svg {
  width: 36px;
  height: 36px;
  fill: #fff;
}

.recordingBtn.playPauseBtn .btn.pauseBtn span svg {
  width: 30px;
  height: 30px;
}

.recordingBtn .btn.rec span {
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #E44141;
}

.recordingBtn .btn.retakeBtn span {
  width: auto;
  height: auto;
  background: transparent;
}

.recordingBtn .btn.retakeBtn svg {
  height: 30px;
  width: 30px;
  color: var(--whiteColor);
}

.recordingBtn .text {
  position: absolute;
  bottom: -24px;
  font-weight: 600;
  color: #fff;
  font-size: 14px;
}

@keyframes pulse {
  0% {
    box-shadow: 0px 0px 2px 0px rgba(173, 0, 0, .3);
  }

  65% {
    box-shadow: 0px 0px 2px 8px rgba(173, 0, 0, .3);
  }

  90% {
    box-shadow: 0px 0px 2px 8px rgba(173, 0, 0, .3);
  }
}

.blink_me {
  position: absolute;
  top: 0px;
  right: 0px;
  color : red;
  font-weight: 800;
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}


.qrCode {
  background: #FEFEFE;
  box-shadow: 0px 0px 38.6613px rgba(0, 0, 0, 0.12);
  border-radius: 24px;
  flex-basis: 20%;
  text-align: center;
  padding: 32px 24px;
}

.deviceImg img {
  max-width: 220px;
}

.qrCode .qrCodeImg {
  padding: 20px;
}

.qrCodeImg img {
  max-width: 220px;
}

.qrCode .title {
  font-size: 20px;
  font-weight: 700;
  color: var(--primaryColor);
  line-height: 1.5;
  margin: 0 0 14px;
}

.qrCode .text {
  font-size: 14px;
  font-weight: 400;
  color: var(--primaryColor);
  line-height: 1.2;
}


/* Recording Link Popup */
.recordingLinkPopup .ant-modal-header {
  padding: 36px 24px 0px;
  border-bottom: none;
  background-color: transparent !important;
}

.recordingLinkPopup .ant-modal-title {
  color: var(--primaryColor);
  font-size: 30px;
  font-weight: 600;
  text-align: left !important;
}

.ant-modal-body {
  color: var(--primaryColor);
}


/* My Info Page */
.customTab .ant-tabs-nav {
  margin: -50px -50px 0;
  background: #DFE3F1;
  box-shadow: none;
}

.customTab .ant-tabs-nav-wrap {
  height: 80px;
}

.customTab .ant-tabs-tab {
  font-size: 16px;
  color: var(--primaryColor);
  font-weight: 700;
  text-transform: uppercase;
}

.customTab .ant-tabs-tab+.ant-tabs-tab {
  margin: 0 0 0 85px;
}

.customTab .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--primaryColor);
}

.customTab .ant-tabs-ink-bar {
  background-color: var(--primaryColor);
}

.customTab .tabTitle {
  font-size: 50px;
  color: var(--primaryColor);
  font-weight: 700;
  margin: 50px 0;
}

.tabContent {
  max-width: 550px;
  margin: 0 auto;
}

.formBtnWrap {
  position: relative;
}

.formBtnWrap .formControl {
  padding-right: 145px;
}

.formBtnWrap .formBtn {
  border-radius: 30px;
  height: 48px;
  width: 140px;
  background: #DFE3F1;
  border-color: #DFE3F1;
  position: absolute;
  right: 0;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--primaryColor);
}

.myInfoWrap .primaryBtn {
  line-height: 48px;
  height: 48px;
}


/* Doc Activity Tab */
.timelineList .ant-timeline {
  padding: 20px 20px 0;
}

.timelineList .ant-timeline-item-head-custom {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.timelineList .ant-timeline-item-content {
  top: -12.001px;
  margin: 0 0 0 50px;
  color: #1F2732;
  font-size: 16px;
  line-height: normal;
  font-weight: 500;
}

.timelineList .ant-timeline-item-content .activityText {
  display: block;
}

.timelineList .ant-timeline-item-head-custom svg {
  display: inline-block;
  width: 24px;
  height: 24px;
}

.timelineList .ant-timeline-item {
  padding-bottom: 44px;
}

.timelineList .ant-timeline-item-tail {
  border-left: 2px dashed #ABAEBB;
}

.timeAgo {
  color: #889CAF;
  font-size: 12px;
  font-weight: 500;
}


/* Doc Pending & Signed Tab */
.docCard .docInner {
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--lightBgColor);
  border-radius: 12px;
  padding: 20px 16px;
  background-color: var(--lightBgColor01);
  flex-wrap: wrap;
/*  cursor: pointer; */
  width: 100%;
}

.docCard:not(:last-child) .docInner {
  margin-bottom: 16px;
}

.docInner .ant-typography {
  margin: 0 !important;
}

.docCardContent {
  position: relative;
  padding-left: 55px;
  flex-basis: calc(100% - 90px);
  padding-right: 10px;
}

.docCardContent .anticon {
  position: absolute;
  left: 0;
  top: 6px;
  background: #fab43a;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.docCardContent .anticon svg {
  display: inline-block;
  fill: #ffffff;
  height: 24px;
  width: 24px;
}

.docInner .ant-typography {
  margin: 2px 0 !important;
  font-size: 16px;
  word-break: break-all;
  line-height: 1.4;
}

.docCardWrap .ant-typography code .docCardIcon {
  background: var(--secondaryColor);
  width: 16px;
  height: 16px;
  line-height: 22px;
  text-align: center;
  border-radius: 50%;
  margin-right: 2px;
  vertical-align: -2px;
}

.docCardWrap .ant-typography code .docCardIcon svg {
  fill: var(--primaryColor);
  width: 9px;
}

.docCardWrap .ant-typography code .docCardIcon.docRedIcon {
  background-color: transparent;
  width: auto;
  height: auto;
  margin-left: 2px;
}

.docCardWrap .ant-typography code .docCardIcon.docRedIcon svg {
  fill: var(--redColor);
  width: 14px;
  height: 14px;
}

.docInner .ant-typography font {
  color: #222222;
}

.docInner .ant-typography font {
  font-size: 14px;
}

.docInner h5.ant-typography font {
  color: var(--primaryColor);
}

.docCardAction .ant-btn.ant-dropdown-trigger {
  padding: 0;
  border: 0;
  height: inherit;
  line-height: normal;
  background: none;
  font-size: 20px;
  color: var(--primaryColor);
}

.docCardWrap {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 55px;
}

.docCardWrap .docCardInnerBox {
  background: var(--whiteColor);
  margin-top: 15px;
  padding: 10px 15px 15px;
  border-radius: 6px;
}

.docCardWrap .ant-typography code {
  color: var(--blackColor);
  font-weight: 600;
  border: none;
  background-color: transparent;
  font-size: 12px;
  font-family: 'DM Sans', sans-serif !important;
}

.docCardWrap .ant-btn-link.seeDocumentLink {
  border: none;
  border-radius: 3px;
  display: inline-block;
  margin: 10px 5px 0 0;
  background: var(--secondaryColor);
  padding: 3px 10px;
  font-weight: bold;
  color: var(--primaryColor);
  font-size: 12px;
  font-family: 'DM Sans', sans-serif !important;
}

.docCardWrap .ant-btn-link.seeDocumentLink:disabled {
  background: gray;
}

.moreInfoLink {
  color: var(--primaryColor);
  font-size: 12px;
  font-weight: 700;
}

.docCardWrap .ant-btn-link:hover {
  color: var(--blackColor);
}

.ant-modal-root .customPopup .ant-modal-header {
  background: var(--secondaryColor);
  border-radius: 2px 2px 0 0;
}

.ant-modal-root .customPopup .ant-modal-title {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
}

.customPopup .ant-tabs-tab-btn {
  font-size: 18px;
  font-weight: 700;
}

.customPopup .ant-tabs-tab {
  padding: 0px 0 12px;
}

.customPopup .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--primaryColor);
}

.customPopup .ant-tabs-ink-bar {
  position: absolute;
  background: var(--primaryColor);
  pointer-events: none;
}

.customPopup .ant-tabs-tab:hover {
  color: var(--primaryRgba);
}

.customPopup .ant-modal-footer button {
  line-height: 48px;
  height: 48px;
  padding: 0 24px;
  text-transform: uppercase;
  font-size: 16px;
  background: var(--secondaryColor);
  border: 1px solid var(--secondaryColor);
  color: var(--primaryColor);
  font-weight: 700;
  transition: var(--transition01);
  cursor: pointer;
  border-radius: 8px;
  display: inline-block;
}

.customPopup .ant-modal-footer {
  text-align: center;
}

.contract-prog-info .ant-tabs-tabpane div>font {
  font-size: 16px;
  margin: 0 0 10px;
  display: block;
  font-weight: 500;
}

.contract-prog-info .ant-tabs-tabpane div>font~font {
  margin-top: 20px;
}

.contract-prog-info .anticon.anticon-close.ant-modal-close-icon path {
  fill: #222222;
}

.contract-prog-info .progressStatusTab .progressTxt {
  margin: 0;
}

.progressStatusTab .progressStatus {
  padding: 15px 0;
}

.progressStatusTab .progressStatus:not(:last-child) {
  border-bottom: 1px solid var(--lightBgColor);
}

.contract-prog-info .progressStatus .progressTime {
  font-size: 12px;
  margin: 0;
  color: var(--secondaryColor);
}

.actionBtnWrap .ant-btn {
  width: 28px;
  height: 26px;
  padding: 0;
  margin: 1px;
  border-radius: 4px;
  background: var(--whiteColor);
  color: var(--whiteColor);
}

.actionBtnWrap .actionDelete {
  color: var(--redColor);
}

.actionBtnWrap .actionMail {
  color: var(--secondaryColor);
}

.actionBtnWrap .actionDownload {
  color: var(--primaryColor);
}


/* Second Signer */
.secondSigner .stepProcessBar .ant-steps::after {
  width: 65%;
}

.dashboardContentWrap.secondSigner {
  margin-top: 96px;
}

.signConfirmText {
  font-size: 16px;
  color: var(--primaryColor);
  text-align: center;
  display: block;
}

.stepSpinBtn {
  display: inline-block;
}

.stepSpinBtn .btn {
  width: 150px;
}

.stepFormCard .cardMultiBtn {
  margin: 12px 0;
}

.stepFormCard .cardMultiBtn .primaryBtn {
  width: 140px;
  margin: 5px;
}

.textSecondSinger {
  font-size: 16px;
  color: var(--primaryColor);
}

.btnListWrap {
  display: flex;
  align-items: center;
  margin: 0 5px;
}

.denySignPopup .ant-modal-body {
  margin-bottom: 20px;
}

.denySignPopup .ant-input-textarea {
  width: 100%;
  height: 120px;
}

.denySignPopup h4 {
  font-size: 20px;
  color: var(--primaryColor);
  margin: 10px 0;
}

.denySignPopup .text {
  font-size: 16px;
  color: var(--primaryColor);
}

.denySignPopup textarea.ant-input {
  border-radius: 8px;
}


/* Video Confirm */
.videoConfirmWrap {
  padding: 150px 0 50px;
}

.videoConfirmCard {
	margin-bottom: 20px;
	box-shadow: 0px 0px 38.6613px rgb(0 0 0 / 12%);
	border-radius: 12px;
	padding: 0;
	background: #fff;
	border: none;
}

.videoConfirmCard video {
  width: 100%;
  aspect-ratio: 16/9;
}

.videoConfirmCard .ant-card-head-title {
	color: var(--primaryColor);
}

.videoConfirmWrap .ant-checkbox-wrapper {
  font-size: 16px;
  color: var(--primaryColor);
  margin: 20px 0 30px;
}


/* Thank you Page */
.thankyouPage h2 {
  color: var(--primaryColor);
  font-size: 32px;
  text-align: center;
}

.thankyouPage .text {
  font-size: 18px;
  color: var(--primaryColor);
  margin-bottom: 36px;
  text-align: center;
}


/* Contract Pages */
.contentPages h3 {
  font-size: 24px;
  color: var(--primaryColor);
  padding: 24px 0 10px;
  margin: 0;
  font-weight: 600;
}

.contentPages .main__title {
  font-size: 36px;
  color: var(--blackColor);
}

.contentPages p,
.contentPages li {
  color: var(--blackColor);
  font-size: 16px;
}

.contentPages p:last-child {
  margin-bottom: 0 ;
}

.contentPages a {
  color: var(--primaryColor);
  font-weight: 500;
}

.dropdownItems {
  width: 180px;
  padding: 8px 12px;
}

.contentPages img {
  max-width: 100%;
}


/* Media Query */
@media (min-width: 1200px) {
  .smallShow {
    display: none;
  }
}

@media (min-width: 992px) {

  .mobileCloseNav,
  .adminSideToggleMobile {
    display: none;
  }
}

@media (min-width: 768px) {
  /* .mobileView {
    display: none;
  } */

  .videoBtn .messageIconMobile {
    visibility: hidden;
    display: none;
  }

  .messageIconMobile .importantText {
    display: none;
  }
}

@media (max-width: 1600px) {
  .dashboardCard .cardTitle {
    font-size: 27px;
  }

  .dashboardContentWrap,
  .adminTopNav {
    padding-inline: 25px;
  }

  .dashboardCard {
    padding: 70px 24px;
  }

  .customTab .ant-tabs-nav {
    margin: -50px -25px 0;
  }

  .importantList span {
    width: 24px;
    height: 24px;
    font-size: 12px;
  }

  .importantText {
    padding: 10px 12px 20px;
  }

  .importantList p {
    padding-left: 10px;
    font-size: 14px;
    line-height: 18px;
    flex-basis: calc(100% - 24px);
  }

  .videoBtn .iconOnVideo {
    width: 60px;
  }

  .funnelContent .circle01 {
    right: 10%;
  }
}

@media (max-width: 1400px) {
  .dashboardCard .cardTitle {
    font-size: 22px;
  }

  .dashboardCard {
    padding: 50px 16px;
  }

  .dashboardCard .cardDesWrap {
    margin: 40px 0 0;
  }

  .darboartTitleWrap .dashBoardPageTitle {
    font-size: 36px;
  }

  .homeBanner {
    aspect-ratio: 16/9;
  }

  .requestPopupBody img {
    width: 80px;
    margin-left: 40px;
  }

  .requestPopupBody h3 {
    font-size: 28px;
    line-height: 1.4;
  }

  .requestPopupBody h5 {
    font-size: 20px;
    line-height: 1.6;
  }

  .funnelContent .circle01 {
    right: 0;
  }

  .customTab .tabTitle {
    font-size: 40px;
    margin: 40px 0;
  }
}

@media (max-width: 1199.98px) {
  .content .mainTitle {
    font-size: 32px;
    margin-bottom: 30px;
  }

  .primaryBtn {
    line-height: 48px;
    height: 48px;
    font-size: 16px;
  }

  .requestPopup .ant-modal-footer button {
    line-height: 48px;
    height: 48px;
    padding: 0 24px;
    font-size: 16px;
    max-width: inherit;
    width: auto;
  }

  .bannerContent h1 {
    font-size: 50px;
  }

  .serviceBlock span {
    font-size: 16px;
  }

  .sectionTilteWrap .sectionTilte {
    font-size: 28px;
  }

  .sectionPadding,
  .fullWidthBlock.sectionPadding,
  .bgSection {
    padding: 80px 0;
  }

  .leftRightContent {
      padding-left: 25px;
  }

  .rightImg .leftRightContent {
      padding-right: 25px;
  }

  .content {
    margin: 80px 0;
  }

  .contactSectionWrap {
    padding: 60px 0;
  }

  .darboartTitleWrap {
    margin-bottom: 30px;
  }

  .darboartTitleWrap .dashBoardPageTitle {
    font-size: 30px;
    margin-bottom: 4px;
  }

  .dashboardCard .cardTitle {
    font-size: 16px;
  }

  .featuredCard .ant-card-body {
    padding: 30px 20px;
  }

  .adminMenu .adminNavLink {
    padding: 5px 30px;
    height: 60px;
  }

  .smallHide {
    display: none;
  }

  .stepFormCard .formCardTitle {
    font-size: 24px;
  }

  .dashboardCard {
    padding: 30px 16px;
  }

  .dashboardCard .cardDesWrap {
    margin: 20px 0 0;
  }

  .videoSignWrap {
    flex-flow: wrap;
  }

  .videoDiv {
    flex-basis: 71%;
    min-height: inherit;
  }

  .customTab .tabTitle {
    font-size: 32px;
    margin: 30px 0;
  }
}

@media only screen and (max-width: 991.98px) {
  .primaryBtn {
    line-height: 44px;
    height: 44px;
    font-size: 15px;
    padding: 0 20px;
  }

  .widthBtn {
    width: auto;
  }

  .bannerContent h1,
  .bgSection .bannerContent h1 {
    font-size: 36px;
  }

  .bgSection .bannerContent h2 {
    font-size: 20px;
  }

  .bannerContent h2 {
    font-size: 16px;
  }

  .sectionTilteWrap .sectionTilte {
    font-size: 24px;
  }

  .fullWidthBlock.sectionPadding {
    padding: 0 0 60px;
  }

  .serviceBlock span {
    font-size: 14px;
  }

  .leftRightImg img {
    position: static;
    width: calc(100% + 50px);
    margin-left: -25px;
  }

  .rightImg .container>.ant-row {
    flex-direction: column-reverse;
  }

  .leftRightContent {
    padding: 30px 0 0;
  }

  .rightImg .leftRightContent {
    padding-right: 0;
  }

  .fullWidthBlock.rightImg {
    padding-top: 0;
  }

  .fullWidthBlock .serviceBlock {
    margin-top: 0;
  }

  .featuredCard .cardArrow .anticon {
    font-size: 24px;
  }

  .content {
    padding-top: 70px;
  }

  .faq__wrapper .ant-collapse .ant-collapse-header {
    font-size: 18px;
  }

  .sign-in-up .ant-modal-body {
    padding: 0px 24px 32px;
  }

  .sign-in-up .ant-modal-header {
    padding: 32px 24px 12px;
  }

  .sign-in-up .ant-modal-title {
    font-size: 32px;
  }

  .adminSideNav {
    transform: translateX(-105%);
  }

  .adminSideToggle {
    display: none;
  }

  .adminSideToggleMobile {
    display: block;
  }

  .adminSideNav+.adminRightContent {
    width: 100%;
  }

  .adminSideNav.hide {
    transform: translateX(0);
  }

  .adminSideNav.hide+.adminRightContent {
    width: 100%;
  }

  .mobileCloseNav svg {
    width: 25px;
    height: 25px;
    color: var(--primaryColor);
    cursor: pointer;
  }

  .adminRightMenu {
    gap: 0;
  }

  .adminLogo {
    padding: 15px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .adminLogo img {
    width: 132px;
  }

  .adminTopNav {
    padding: 16px 25px;
  }

  .videoConfirmWrap {
    padding: 110px 0 50px;
  }

  .videoConfirmWrap .ant-checkbox-wrapper {
    font-size: 14px;
    margin: 10px 0 20px;
  }
}

@media (max-width: 767.98px) {

  .sectionPadding,
  .bgSection {
    padding: 60px 0;
  }

  /* .desktopView {
    display: none;
  } */

  .content .mainTitle {
    font-size: 28px;
    margin-bottom: 24px;
  }

  .formControl {
    height: 42px;
    font-size: 14px;
    padding: 0 16px;
  }

  .myInfoWrap .primaryBtn {
    line-height: 42px;
    height: 42px;
  }

  .changeSignInnerPopup .langSelect {
    line-height: 40px;
    padding: 0px 16px;
    font-size: 14px;
  }

  .formBtnWrap .formBtn {
    height: 42px;
    width: 100px;
    font-size: 13px;
  }

  .formBtnWrap .formControl {
    padding-right: 105px;
  }

  .darboartTitleWrap .dashBoardPageTitle {
    font-size: 24px;
  }

  .bannerContent h1 {
    font-size: 32px;
  }

  .scrollDwon {
    width: 24px;
    height: 40px;
    bottom: 15px;
  }

  .scrollDwon::after {
    height: 11px;
    left: 9px;
  }

  .bgSection .bannerContent h1 {
    font-size: 24px;
  }

  .bgSection .bannerContent h2 {
    font-size: 18px;
  }

  .sectionTilteWrap .sectionTilte {
    font-size: 20px;
  }

  .sectionTilteWrap span {
    font-size: 15px;
  }

  .featuredCard .cardText h5 {
    font-size: 16px;
  }

  .featuredCard .cardText span {
    font-size: 15px;
  }

  .contactSectionWrap {
    padding: 40px 0;
  }

  .content {
    padding-top: 64px;
    margin: 60px 0;
  }

  .faq__wrapper .ant-collapse .ant-collapse-header {
    font-size: 16px;
  }

  .faq__wrapper .ant-collapse-content>.ant-collapse-content-box {
    font-size: 15px;
  }

  .sign-in-up .ant-modal-title {
    font-size: 24px;
  }

  .popupText {
    font-size: 16px;
  }

  .ant-modal-wrap {
    padding: 0 10px;
  }

  .dashboardCard {
    min-height: inherit;
  }

  .addRecepientsForm .title,
  .recipientDetail .title {
    font-size: 20px;
    margin: 0 0 10px;
  }

  .adminLogo {
    padding: 15px 25px;
  }

  .adminMenu ul .ant-menu-item {
    padding: 10px 25px !important;
    height: 60px !important;
  }

  .adminMenu ul .ant-menu-item .ant-menu-title-content img {
    width: 24px;
  }

  .stepFormCard {
    padding: 24px;
  }

  .recepientsClose {
    width: 32px;
    height: 32px;
    line-height: 32px;
    right: 24px;
    top: 12px;
  }

  .recepientsAdd {
    width: 50px;
    height: 50px;
    right: 10px;
    bottom: -15px;
  }

  .recepientsAdd svg {
    width: 20px;
    height: 20px;
  }

  .addRecepientsForm {
    padding: 16px 20px 16px;
    border-radius: 12px;
  }

  .addRecepientsForm:not(:last-child) {
    margin-bottom: 16px;
  }

  .stepFormBtn .btn {
    line-height: 40px;
    max-width: 130px;
    height: 40px;
    margin: 4px;
  }

  .stepFormCard .primaryBtn {
    width: auto;
  }

  .stepFormCard .formCardTitle {
    font-size: 22px;
    margin-bottom: 16px;
  }

  .stepFormBtn .btn svg {
    width: 18px;
    height: 18px;
  }

  .stepProcessBar {
    display: none;
  }

  .stepFormCard {
    margin-top: 0 !important;
  }

  .funnelContent .circle01 {
    right: -20px;
    width: 160px;
    top: -30px;
  }

  .funnelContent .circle02 {
    max-width: 340px;
  }

  .requestPopupBody h3 {
    font-size: 24px;
  }

  .requestPopupBody h5 {
    font-size: 16px;
  }

  .videoSignWrap {
    margin: auto -25px;
    width: calc(100% + 50px);
    margin-top: -50px;
  }

  .videoImportant {
    flex-basis: 100%;
  }

  .videoDiv {
    flex-basis: 100%;
    margin-bottom: 24px;
    padding: 0;
  }

  .stepFormBtn.videoStepBtn .btn {
    max-width: 180px;
  }

  .importantList p,
  .importantText .title {
    color: var(--whiteColor);
    font-size: 13px;
  }

  .importantText .title {
    margin: 8px 0;
  }

  .importantListWrap .importantList:not(:last-child) {
    margin-bottom: 8px;
  }

  .importantText {
    padding: 10px;
  }

  .videoDivInner {
    position: relative;
    padding: 0 0 20px;
    overflow: hidden;
  }

  .videoBtn {
    padding-inline: 16px;
  }

  .videoBtn .messageIcon {
    visibility: visible;
    width: 60px;
    height: 60px;
  }

  .videoImportant {
    display: none;
  }

  .importantText::before,
  .qrCode {
    display: none;
  }

  .importantText {
    background: rgba(15, 78, 126, 0.7);
    box-shadow: none;
    top: auto;
    -webkit-transform: var(--transition01);
    transform: var(--transition01);
    bottom: 100px;
    text-align: left;
    left: 0;
    width: calc(100% - 0px);
  }

  .customTab .tabTitle {
    font-size: 24px;
  }

  .customTab .ant-tabs-tab+.ant-tabs-tab {
    margin: 0 0 0 40px;
  }

  .customTab .ant-tabs-tab {
    font-size: 14px;
  }

  .customTab .ant-tabs-nav-wrap {
    height: 60px;
  }

  .timelineList .ant-timeline-item-head-custom {
    width: 36px;
    height: 36px;
  }

  .timelineList .ant-timeline-item-head-custom img {
    width: 100%;
  }

  .timelineList .ant-timeline-item-content {
    margin: 0 0 0 40px;
    font-size: 15px;
    top: -10px;
  }

  .timelineList .ant-timeline-item {
    padding-bottom: 24px;
  }

  .docCardContent .anticon {
    width: 30px;
    height: 30px;
  }

  .docCardWrap,
  .docCardContent {
    padding-left: 45px;
  }

  .docCardContent .anticon svg {
    height: 16px;
    width: 16px;
  }

  .docCardWrap .docCardInnerBox {
    padding: 10px 12px 15px;
    font-size: 13px;
  }

  .dashboardContentWrap.secondSigner {
    margin-top: 30px;
  }

  .ant-modal.customPopup {
    max-width: 100%;
    flex: inherit;
  }

  .thankyouPage h2 {
    font-size: 24px;
  }

  .thankyouPage .text {
    font-size: 16px;
    margin-bottom: 24px;
  }

  .dashboardContentWrap.afterScan {
    margin-top: 65px;
  }

  .afterScan .stepFormBtn.videoStepBtn .btn {
    max-width: 230px;
  }

  .contentPages .main__title {
    font-size: 28px;
    color: var(--blackColor);
  }

  .contentPages {
    padding-top: 50px;
  }

  .contentPages h3 {
    font-size: 18px;
  }

  .contentPages p,
  .contentPages li {
    font-size: 14px;
  }

  .timelineList .ant-timeline {
    padding: 10px 11px 0;
  }

  .videoConfirmWrap .ant-col {
      width: 100%;
  }

  .videoDiv video {
    aspect-ratio: 1/1;
    object-fit: cover;
  }

  .bannerVideo+.container {
      top: 58%;
  }
}

@media (max-width: 575.98px) {
  .bannerContent h1 {
    font-size: 24px;
  }

  .notiIcon {
    width: 32px;
    height: 32px;
    flex-basis: 32px;
    line-height: 30px;
    margin-left: 10px;
  }

  .notiIcon img {
    width: 16px;
  }

  .adminUser img.user-logo {
    width: 32px;
    height: 32px;
  }

  .adminUser .userName {
    font-size: 12px;
  }

  .adminLogo img {
    width: 115px;
  }

  .homeBanner {
    height: 360px;
    width: 100%;
    background: #0b264c;
  }

  .bannerContent h2 {
    margin: 0 0 16px !important;
  }

  .contactSection {
    flex-flow: wrap;
  }

  .contactSection>div {
    flex-basis: 100%;
    text-align: center;
  }

  .contactEmail span {
    font-size: 18px;
    margin: 0 0 20px;
    display: block;
  }

  .serviceBlock {
    margin: 12px 0 !important;
  }

  .agreeFileMobile>div {
    width: 100%;
  }

  .fileDetial {
    max-width: 100%;
  }

  .changeSignMobile>div {
    flex-basis: 100%;
    max-width: 100%;
  }

  .docCardWrap .ant-typography code {
    display: block;
    margin: 0;
    padding: 4px 0 0;
  }

  .signConfirmText {
    font-size: 14px;
  }

  .docFileImg {
    height: 400px;
  }

  .docFileImg .pcScrollWindow {
    height: 400px !important;
  }

  .docCardContent {
    flex-basis: calc(100% - 30px);
  }

  .actionBtnWrap {
    flex-basis: 28px;
  }

  .formLineBtn .btnImg {
    padding: 8px;
  }

  .formLineBtn .btnImg img {
    width: 34px;
  }

  #signInDiv iframe {
    transform: scale(1.1);
  }

  .videoBtn .iconOnVideo {
      width: 40px;
  }

  .recordingBtn .btn, .videoBtn .messageIcon {
      width: 40px;
      height: 40px;
  }

  .recordingBtn .btn span, .recordingBtn.playPauseBtn .btn.pauseBtn span svg {
      width: 20px;
      height: 20px;
  }

  .recordingBtn.playPauseBtn .btn span svg, .recordingBtn .btn.rec span {
      width: 24px;
      height: 24px;
  }

  .retakeBtn img {
      width: 16px;
  }

  .recordingBtn .text {
      font-size: 12px;
  }

  .messageIcon img {
      width: 20px;
  }

  .importantText {
      bottom: 78px;
  }
}

@media (max-width:379.98px) {
  .signConfirmText {
    font-size: 13px;
  }

  .bannerContent h1 {
      font-size: 20px;
      margin: 0 0 12px;
  }

  .bannerContent h2 {
      font-size: 14px;
  }
}